<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="5vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog('ReconciliationEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="form.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="form.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="form.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="form.state===3" type="danger" size="mini">已驳回</el-tag>
            </template>
            <div>
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="ReconciliationEditForm"
                    label-width="100px"
                    size="mini"
                >
                    <div v-if="show_type">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="账单标题" prop="title">
                                    <el-input v-model="form.title">
                                        <el-button type="primary" round plain @click="search_table" slot="suffix" v-if="sel_auth">账单数据查询</el-button>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="所属客户" prop="client_id">
                                    <el-select
                                        v-model="form.client_id"
                                        filterable
                                        placeholder="请选择客户"
                                        class="clientc"
                                        @change="(e) => ClientChange(e)"
                                    >
                                        <el-option
                                            v-for="item in AllClient"
                                            :key="item.name"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="产品名称" prop="product_id">
                                    <el-select
                                        v-model="form.product_id"
                                        filterable
                                        placeholder="请选择产品"
                                        class="product_idc"
                                        clearable
                                        @change="(e) => DataChange(e)"
                                    >
                                        <el-option
                                            v-for="item in AllProduct"
                                            :key="item.name"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="账单类型" prop="type">
                                    <el-select
                                        v-model="form.type"
                                        filterable
                                        placeholder="请选择账单类型"
                                        class="typec"
                                        @change="(e) => DataChange(e)"
                                    >
                                        <el-option
                                            v-for="(v,i) in ['仓储','装卸','仓租']"
                                            :key="v+i"
                                            :label="v"
                                            :value="i"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="开始日期" prop="start_time">
                                    <el-date-picker
                                        v-model="form.start_time"
                                        align="right"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd"
                                        class="start_timec"
                                        @change="(e) => DataChange(e)"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="结束日期" prop="end_time">
                                    <el-date-picker
                                        v-model="form.end_time"
                                        align="right"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd"
                                        class="start_timec"
                                        @change="(e) => DataChange(e)"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="table_data.length > 0">
                            <el-col :span="12">
                                <el-form-item label="账单金额">
                                    <span class="select_data_sumc">{{this.form.select_data_sum | diy_money}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="调账金额" prop="trim_money">
                                    <el-input v-model="form.trim_money" clearable><i slot="suffix">元</i></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="备注" prop="note">
                                <el-input type="textarea" :rows="3" v-model="form.note"></el-input>
                            </el-form-item>
                            </el-col>
                        </el-row>
                        <div v-if="(table_data.length > 0) && (form.type === 0)">
                            <common-table-component
                                :col_arr="col_arr_money_bill"
                                :table_data="table_data"
                                table_size="mini"
                                table_height="300px"
                                :duoxuan="true"
                                @selects="selects"
                            ></common-table-component>
                        </div>
                        <div v-if="(table_data.length > 0) && (form.type === 1)">
                            <common-table-component
                                :col_arr="col_arr_entry_detry"
                                :table_data="table_data"
                                table_size="mini"
                                table_height="300px"
                                :duoxuan="true"
                                @selects="selects"
                            ></common-table-component>
                        </div>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="3"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item :span="3">
                                <template slot="label">
                                    <span>账单标题</span>
                                </template>
                                <span>{{form.title}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="3">
                                <template slot="label">
                                    <span>所属客户</span>
                                </template>
                                <span>{{form.client_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>账单类型</span>
                                </template>
                                <span>{{['仓储','装卸','仓租'][form.type]}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>开始日期</span>
                                </template>
                                <span>{{form.start_time}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>结束日期</span>
                                </template>
                                <span>{{form.end_time}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>对账状态</span>
                                </template>
                                <el-tag v-if="form.account_state===0" type="info">未对账</el-tag>
                                <el-tag v-if="form.account_state===1">对账中</el-tag>
                                <el-tag v-if="form.account_state===2" type="success">已对账</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>开票状态</span>
                                </template>
                                <el-tag v-if="form.invoicing_state===0" type="info">未开票</el-tag>
                                <el-tag v-if="form.invoicing_state===1" type="success">已开票</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>回款状态</span>
                                </template>
                                <el-tag v-if="form.back_money_state===0" type="info" effect="plain">未回款</el-tag>
                                <el-tag v-if="form.back_money_state===1" type="warning" effect="plain">部分回款</el-tag>
                                <el-tag v-if="form.back_money_state===2" type="success" effect="plain">完全回款</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'center','color':'#2828FF'}">
                                <template slot="label">
                                    <span>账单总金额</span>
                                </template>
                                <span>{{form.must_money |diy_money}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'center','color':trim_money_color(form)}">
                                <template slot="label">
                                    <span>调账后金额</span>
                                </template>
                                <span>{{form.trim_money | diy_money}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                <template slot="label">
                                    <span>已回款金额</span>
                                </template>
                                <span>{{form.back_money | diy_money}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="3" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <span>备注</span>
                                </template>
                                <span>{{form.note}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                        <div v-if="(table_data.length > 0) && (form.type === 0)" style="margin-top: 10px;">
                            <common-table-component
                                :col_arr="col_arr_money_bill"
                                :table_data="table_data"
                                table_size="mini"
                                table_height="300px"
                            ></common-table-component>
                        </div>
                        <div v-if="(table_data.length > 0) && (form.type === 1)" style="margin-top: 10px;">
                            <common-table-component
                                :col_arr="col_arr_entry_detry"
                                :table_data="table_data"
                                table_size="mini"
                                table_height="300px"
                            ></common-table-component>
                        </div>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="to_approval_auth"
                        type="success"
                        plain
                        @click="to_approval('ReconciliationEditForm')"
                        >提交</el-button
                    >
                    <el-button
                        v-if="sel_approval_auth"
                        type="warning"
                        plain
                        @click="sel_approval()"
                        >查看审批</el-button
                    >
                    <el-button
                        v-if="withdraw_auth"
                        type="danger"
                        plain
                        @click="reconciliationWithdraw('ReconciliationEditForm')"
                        >审批撤回</el-button
                    >
                    <el-button
                        v-if="edit_auth"
                        type="primary"
                        plain
                        @click="show_edit"
                        >修改</el-button
                    >
                    <el-button
                        v-if="del_auth"
                        type="danger"
                        plain
                        @click="ReconciliationDel('ReconciliationEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('ReconciliationEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ReconciliationEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ReconciliationEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('ReconciliationEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ReconciliationEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog('ReconciliationEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="form.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="ReconciliationApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'

import {
    reconciliation_add_request,
    reconciliation_del_request,
    reconciliation_edit_request,
    reconciliation_sel_request,
    reconciliation_to_approval_request,
    reconciliation_sel_approval_request,
    reconciliation_approval_request,
    reconciliation_withdraw_request
} from '@/network/WareHouse/reconciliation.js'

import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'
import { product_list_request } from '@/network/list.js'

export default {
    name:'ReconciliationEditComponent',
    data(){
        return {
            login_loading: false,
            ApprovalDialogVisible: false,
            form: {
                title: '',
                type: '',
                client_id: '',
                product_id: '',
                start_time: '',
                end_time: '',
                trim_money: '',
                note: '',
                select_data: [],
                select_data_sum: 0
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '请输账单标题',
                        trigger: 'blur',
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请填写账单类型',
                        trigger: 'blur',
                    }
                ],
                client_id: [
                    {
                        required: true,
                        message: '请选择所属客户',
                        trigger: 'blur',
                    },
                ],
                start_time: [
                    {
                        required: true,
                        message: '请选择开始日期',
                        trigger: 'blur',
                    }
                ],
                end_time: [
                    {
                        required: true,
                        message: '请选择结束日期',
                        trigger: 'blur',
                    }
                ],
                trim_money: [
                    {
                        required: true,
                        message: '请填写调账金额',
                        trigger: 'blur',
                    }
                ],
            },
            AllProduct: [],
            table_data: [],
            ApprovalData: [],
            ReconciliationApprovalRequest:reconciliation_approval_request,
            col_arr_money_bill: [
                {
                    prop: 'bill_date',
                    label: '费用日期',
                    minWidth: '90px'
                },
                {
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '300px',
                    filter: true
                },
                {
                    prop: 'weight',
                    label: '重量',
                    minWidth: '130px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['weight']))+'KG'
                    }
                },
                {
                    prop: 'tray_num',
                    label: '托盘数',
                    minWidth: '100px',
                    editdata(row) {
                        if(row['tray_num']) {
                            return row['tray_num']+'个'
                        } else {
                            return ''
                        }
                    }
                },
                {
                    prop: 'storage_money',
                    label: '仓储费',
                    minWidth: '100px',
                    align: 'right',
                    editdata(row) {
                        return '￥'+thousandBitSeparator(fomatFloat(row['storage_money'],4))
                    }
                }
            ],
            col_arr_entry_detry: [
                {
                    prop: 'date',
                    label: '费用日期',
                    minWidth: '90px',
                },
                {
                    prop: 'type',
                    label: '类型',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d==='entry') {
                            return {
                                type: 'success',
                                filter_data: '入库'
                            }
                        } else {
                            return {
                                type: 'danger',
                                filter_data: '出库'
                            }
                        }
                    }
                },
                {
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '300px',
                    filter: true
                },
                {
                    prop: 'weight',
                    label: '重量',
                    minWidth: '100px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['weight']))+'KG'
                    }
                },
                {
                    prop: 'tray_num',
                    label: '托盘数',
                    minWidth: '70px',
                    editdata(row) {
                        if(row['tray_num']) {
                            return row['tray_num']+'个'
                        } else {
                            return ''
                        }
                    }
                },
                {
                    prop: 'labour_money',
                    label: '装卸费',
                    minWidth: '90px',
                    align: 'right',
                    editdata(row) {
                        return '￥'+thousandBitSeparator(fomatFloat(row['labour_money'],4))
                    }
                },
                {
                    prop: 'other_money',
                    label: '其它费',
                    minWidth: '90px',
                    align: 'right',
                    editdata(row) {
                        return '￥'+thousandBitSeparator(fomatFloat(row['other_money'],4))
                    }
                }
            ]
        }
    },
    computed:{
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.form.name ? '仓储账单：' + this.form.name : '仓储账单详情'
            } else if (this.show_type === 1) {
                return '添加仓储账单'
            } else {
                return '修改仓储账单'
            }
        }
    },
    methods:{
        closeDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ReconciliationInsert(formName)
            })
        },
        ReconciliationInsert(formName) {
            if(this.form.select_data.length === 0) {
                this.$message.warning('未选择账单数据')
                return
            }

            this.login_loading = true
            reconciliation_add_request(this.form)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.form = {
                title: '',
                type: '',
                client_id: '',
                product_id: '',
                start_time: '',
                end_time: '',
                trim_money: '',
                note: '',
                select_data: [],
                select_data_sum: 0
            }
            this.table_data = []
            this.AllProduct = []
        },
        show_edit() {
            this.table_data = []
            this.form.select_data_sum = 0
            this.form.trim_money = 0
            this.$emit('show_edit')
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ReconciliationEdit(formName)
            })
        },
        ReconciliationEdit(formName) {
            this.login_loading = true
            this.form.id = this.id
            reconciliation_edit_request(this.form)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.form.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        ReconciliationDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    reconciliation_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        },
        search_table() {
            if((this.form.type==='') || (this.form.client_id==='') || (this.form.start_time==='') || (this.form.end_time==='')) {
                this.$message.warning('请先填写账单类型、所属客户、开始日期、结束日期后再进行查询')
                return
            }
            if(this.form.type === 2) {
                this.$message.warning('仓租账单待开发')
                return
            }

            const cond = {
                type: this.form.type,
                client_id: this.form.client_id,
                product_id: this.form.product_id,
                start_time: this.form.start_time,
                end_time: this.form.end_time
            }

            this.login_loading = true
            reconciliation_sel_request(cond)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.table_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        selects(rows) {
            this.form.select_data = []
            this.form.select_data_sum = 0
            this.form.trim_money = 0
            if(this.form.type === 0) {
                rows.forEach(v => {
                    this.form.select_data.push({
                        date: v.bill_date,
                        product_id: v.product_id
                    })
                })
                this.form.select_data_sum = rows.reduce((s,cur) => {
                    return s + cur.storage_money * 1
                },0)
                this.form.trim_money = fomatFloat(this.form.select_data_sum,4)
            } else if(this.form.type === 1) {
                rows.forEach(v => {
                    this.form.select_data.push({
                        type: v.type,
                        date: v.date,
                        product_id: v.product_id
                    })
                })
                this.form.select_data_sum = rows.reduce((s,cur) => {
                    return s + cur.labour_money*1 + cur.other_money*1
                },0)
                this.form.trim_money = fomatFloat(this.form.select_data_sum,4)
            }
                
        },
        DataChange() {
            this.table_data = []
            this.form.select_data = []
            this.form.select_data_sum = 0
            this.form.trim_money = 0
        },
        ClientChange(e) {
            this.DataChange()
            this.get_product_list({with_client_id: e})
        },
        get_product_list(param = {}) {
            product_list_request(param)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.AllProduct = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        to_approval(formName) {
            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    reconciliation_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            reconciliation_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        reconciliationWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    reconciliation_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        },
        trim_money_color(form) {
            if(fomatFloat(form.trim_money) === fomatFloat(form.must_money)) {
                return '#2828FF'
            } else {
                return '#FF0000'
            }
        }
    },
    filters:{
        diy_money(v) {
            return '￥'+thousandBitSeparator(fomatFloat(v,4))
        }
    },
    props:{
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        reconciliation_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllClient: {
            type:Array,
            default() {
                return []
            },
        },
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonTableComponent,
        CommonApprovalDialog
    },
    watch:{
        reconciliation_details_data(newVal) {
            this.form = newVal
            this.table_data = this.form.table_data
            delete this.form.table_data
            this.get_product_list({with_client_id: newVal.client_id})
        },
    }
}
</script>

<style scopde>
.clientc {
    width: 100%;
}
.typec {
    width: 100%;
}
.product_idc {
    width: 100%;
}
.el-date-editor.el-input {
    width: 100%;
}
.select_data_sumc {
    font-weight: bold;
    color: blue;
}
</style>