<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="账单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="账单号搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="账单标题" prop="title">
                <el-input
                    v-model="form.title"
                    placeholder="账单标题搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="账单类型" prop="type">
                <el-select
                    v-model="form.type"
                    placeholder="选择账单类型"
                    class="typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['仓储','装卸','仓租']"
                        :key="v+i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属客户" prop="client_id">
                <el-select
                    v-model="form.client_id"
                    placeholder="选择客户"
                    class="clientc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="client in AllClient"
                        :key="client.name"
                        :label="client.name"
                        :value="client.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="对账状态" prop="account_state">
                <el-select
                    v-model="form.account_state"
                    placeholder="选择对账状态"
                    class="account_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未对账','对账中','已对账']"
                        :key="v+i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开票状态" prop="invoicing_state">
                <el-select
                    v-model="form.invoicing_state"
                    placeholder="选择开票状态"
                    class="invoicing_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未开票','已开票']"
                        :key="v+i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="回款状态" prop="back_money_state">
                <el-select
                    v-model="form.back_money_state"
                    placeholder="选择回款状态"
                    class="back_money_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未回款','部分回款','完全回款']"
                        :key="v+i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >新建仓储账单</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'ReconciliationSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                title: '',
                type: '',
                account_state: '',
                invoicing_state: '',
                back_money_state: '',
                client_id: '',
                page: 1,
            },
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        add_auth: String,
        AllClient: Array
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>