import request from '../request'

//仓储账单列表
export const reconciliation_index_request = p => {
    return request({
        method:'GET',
        url:'reconciliation/index',
        params: p
    })
}

//新建仓储账单
export const reconciliation_add_request = d => {
    return request({
        method:'POST',
        url:'reconciliation/add',
        data: {
            title: d.title,
            type: d.type,
            client_id: d.client_id,
            start_time: d.start_time,
            end_time: d.end_time,
            trim_money: d.trim_money,
            note: d.note,
            select_data: d.select_data
        }
    })
}

//删除仓储账单
export const reconciliation_del_request = id => {
    return request({
        method:'DELETE',
        url:'reconciliation/del',
        data: {
            id: id
        }
    })
}

//修改仓储账单
export const reconciliation_edit_request = d => {
    return request({
        method:'PUT',
        url:'reconciliation/edit',
        data: {
            id: d.id,
            title: d.title,
            type: d.type,
            client_id: d.client_id,
            start_time: d.start_time,
            end_time: d.end_time,
            trim_money: d.trim_money,
            note: d.note,
            select_data: d.select_data
        }
    })
}

//仓储账单详情
export const reconciliation_details_request = id => {
    return request({
        method:'GET',
        url:'reconciliation/details',
        params: {
            id
        }
    })
}

//仓储账单数据查询
export const reconciliation_sel_request = p => {
    return request({
        method:'GET',
        url:'reconciliation/sel',
        params: p
    })
}

// 提交仓储账单
export const reconciliation_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'reconciliation/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看仓储账单审批记录
export const reconciliation_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'reconciliation/sel_approval',
        params: {
            id
        }
    })
}

// 审批仓储账单
export const reconciliation_approval_request = d => {
    return request({
        method:'PUT',
        url:'reconciliation/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回仓储账单
export const reconciliation_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'reconciliation/withdraw',
        data: {
            id: d.id
        }
    })
}