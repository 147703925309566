<template>
    <div v-loading.fullscreen.lock="loading">
        <reconciliation-search-bar-component
            add_auth="新建仓储账单"
            :AllClient="AllClient"
            @search="get_reconciliation_index"
            @addTeam="addTeam"
        ></reconciliation-search-bar-component>
        <common-table-component
            details_auth="仓储账单详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_reconciliation"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_reconciliation_index"
        ></common-page-component>
        <reconciliation-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :reconciliation_details_data="freconciliation_details_data"
            :AllClient="AllClient"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :sel_auth="sel_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            @exitDialog="dialogExit"
            @search="get_reconciliation_index"
            @show_edit="show_edit"
            @frefresh="frefresh"
            @details_row="details_reconciliation"
        ></reconciliation-edit-component>
    </div>
</template>

<script>
import { reconciliation_index_request, reconciliation_details_request } from '@/network/WareHouse/reconciliation.js'
import { client_list_request } from '@/network/list.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import ReconciliationSearchBarComponent from '@/components/WareHouse/Reconciliation/ReconciliationSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import ReconciliationEditComponent from '@/components/WareHouse/Reconciliation/ReconciliationEditComponent'

export default {
    name: 'ReconciliationView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                title: '',
                type: '',
                account_state: '',
                invoicing_state: '',
                back_money_state: '',
                client_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'account_state',
                    label: '对账状态',
                    minWidth: '90px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未对账'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '对账中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已对账'
                            }
                        }
                    },
                },
                {
                    prop: 'invoicing_state',
                    label: '开票状态',
                    minWidth: '90px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未开票'
                            }
                        } else {
                            return {
                                type: 'success',
                                filter_data: '已开票'
                            }
                        }
                    }
                },
                {
                    prop: 'back_money_state',
                    label: '回款状态',
                    minWidth: '110px',
                    html: (row) => {
                        if(row['back_money_state']===0) {
                            return `<div class="cell"><span class="el-tag el-tag--info el-tag--plain">未回款</span></div>`
                        } else if(row['back_money_state']===1) {
                            return `<div class="cell"><span class="el-tag el-tag--warning el-tag--plain">部分回款</span></div>`
                        } else if(row['back_money_state']===2)  {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">完全回款</span></div>`
                        }
                    }
                },
                {
                    prop: 'name',
                    label: '账单编号',
                    minWidth: '120px'
                },
                {
                    prop: 'title',
                    label: '账单标题',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'type',
                    label: '账单类型',
                    minWidth: '90px',
                    editdata(row) {
                        return ['仓储','装卸','仓租'][row['type']]
                    }
                },
                {
                    prop: 'trim_money',
                    label: '调账金额',
                    minWidth: '120px',
                    align: 'right',
                    html: (row) => {
                        return `<span style="color: #2828FF;">￥`+thousandBitSeparator(fomatFloat(row['trim_money'])) + `</span>`
                    }
                },
                {
                    prop: 'back_money',
                    label: '已回款金额',
                    minWidth: '120px',
                    align: 'right',
                    html: (row) => {
                        if(row['back_money']) {
                            return `<span style="color: #00BB00;">￥` + thousandBitSeparator(fomatFloat(row['back_money'])) + `</span>`
                        } else {
                            return `<span style="color: #00BB00;">￥0.00</span>`
                        }
                    }
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'start_time',
                    label: '开始日期',
                    minWidth: '100px',
                },
                {
                    prop: 'end_time',
                    label: '结束日期',
                    minWidth: '100px',
                },
                {
                    prop: 'client_name',
                    label: '客户名称',
                    minWidth: '300px',
                }
            ],
            freconciliation_details_data: {},
            AllClient: [],
            edit_auth: false,
            del_auth: false,
            sel_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
            this.sel_auth = this.$_has('仓储账单数据查询')
        },
        show_edit() {
            this.edit_type = 2
            this.sel_auth = this.$_has('仓储账单数据查询')
        },
        get_reconciliation_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.title = param.title ?? this.cond.title
            this.cond.type = param.type ?? this.cond.type
            this.cond.account_state = param.account_state ?? this.cond.account_state
            this.cond.invoicing_state = param.invoicing_state ?? this.cond.invoicing_state
            this.cond.back_money_state = param.back_money_state ?? this.cond.back_money_state
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            reconciliation_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_reconciliation(id) {
            this.fid = id
            this.fdialogFormVisible = true
            reconciliation_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.freconciliation_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_client() {
            this.loading = true
            client_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })

                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        frefresh() {
            this.details_reconciliation(this.fid)
            this.get_reconciliation_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false
            
            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交仓储账单')
                    this.edit_auth = this.$_has('修改仓储账单')
                    this.del_auth = this.$_has('删除仓储账单')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回仓储账单')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批仓储账单')
                }
                this.sel_approval_auth = this.$_has('查看仓储账单审批记录')
            } else if(res.state === 2) {
                this.sel_approval_auth = this.$_has('查看仓储账单审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交仓储账单')
                    this.edit_auth = this.$_has('修改仓储账单')
                    this.del_auth = this.$_has('删除仓储账单')
                }
                this.sel_approval_auth = this.$_has('查看仓储账单审批记录')
            }
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_reconciliation_index()
        this.get_all_client()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        ReconciliationSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        ReconciliationEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>